<template>
  <div>
    <Loading v-if="loading" />
    <Header />
    <div class="pa-6 app-container">
      <v-row class="mt-6 justify-center">
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="3"
          v-for="(pagina, index) in paginas"
          :key="pagina.id"
        >
          <CardSelecao
            v-bind:card="pagina"
            v-bind:class="index == paginas.length - 1 ? 'ultimoItem' : ''"
          ></CardSelecao>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Loading from "../../components/loading.vue";
import Header from "../../components/header.vue";
import CardSelecao from "../../components/card-selecao.vue";
import StorageService from "../../service/storage-service.js";
import AprovacaoClienteService from "../../service/aprovacao-cliente-service.js";

const storageService = new StorageService();
const aprovacaoClienteService = new AprovacaoClienteService();

export default {
  name: "AdminHome",
  components: {
    Loading,
    Header,
    CardSelecao,
  },
  data() {
    return {
      paginas: [],
      loading: false
    };
  },
  created: async function () {
    this.loading = true;
    let paginas = JSON.parse(localStorage.getItem("login"))?.paginas;
    const result = await aprovacaoClienteService.totalAprovacoesPendentes();
    if (result?.statusCode == 200) {
      const totalAprovacoesPendentes = result?.data.data;
      if (totalAprovacoesPendentes > 0) {
        const aprovacaoClientes = paginas.filter(x => {
          return x.id == 2;
        })[0];
        aprovacaoClientes.contagem = totalAprovacoesPendentes;
      }
    }
    this.loading = false;
    this.paginas = paginas;
  },
  mounted: async function () {
    await storageService.validarToken();
    setTimeout(() => {
      document.getElementsByTagName("html")[0].style.overflow = "hidden auto";
    }, 100);
  },
};
</script>
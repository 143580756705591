<template>
  <div>
    <v-app-bar color="black" dark height="120px">
      <v-tooltip bottom v-if="!paginaInicial">
        <template v-slot:activator="{ on }">
          <div v-on="on" class="mr-4 btn-voltar" v-on:click="voltar">
            <v-icon size="30px">mdi-arrow-left</v-icon>
          </div>
        </template>
        <span>Voltar</span>
      </v-tooltip>
      <div class="logo-container">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div class="imagem-modulo" v-on="on" @click="irParaLink('/admin/home')">
              <img src="@/assets/logo_dourado.png" width="100px" />
            </div>
          </template>
          <span>Home</span>
        </v-tooltip>
      </div>
      <div class="sair mr-4" @click="exibirSair">
        <v-icon size="30px">mdi-exit-to-app</v-icon> Exit
      </div>

      <div class="header-divisor"></div>
    </v-app-bar>
    <div class="footer">
      Copyright © {{ new Date().getFullYear() }} FM Fabio Merli Hair. All Rights
      Reserved
    </div>
    <div justify="center">
      <v-dialog v-model="modalSair" max-width="600px">
        <v-card>
          <v-toolbar dark color="black">
            <v-toolbar-title>Exit</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pt-4 pl-4 pr-4">
            <p class="title black--text">
              Do you really want to leave the site?
            </p>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              right
              class="text-caption"
              @click="modalSair = false"
            >
              <v-icon class="text-caption">mdi-close</v-icon>No
            </v-btn>
            <v-btn
              color="primary"
              text
              class="text-caption"
              @click="confirmarSair"
            >
              <v-icon class="text-caption">mdi-check</v-icon>Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: ["titulo", "homeModulo", "tooltipModulo"],
  data() {
    return {
      paginaInicial: false,
      modalSair: false,
    };
  },
  methods: {
    confirmarSair() {
      this.modalSair = false;
      localStorage.removeItem("login");
      this.$router.push("/admin/login");
    },
    exibirSair() {
      this.modalSair = true;
    },
    voltar() {
      history.back();
    },
    irParaLink(url) {
      if (this.$route.path !== url)
        this.$router.push(url).catch((failure) => {
          localStorage.setItem("erro", failure);
        });
    },
  },
  mounted: function () {
    this.paginaInicial = this.$router.currentRoute.fullPath == "/admin/home";
    let login = JSON.parse(localStorage.getItem("login"));
    this.usuario = login;
  },
};
</script>
<style scoped>
.sair {
  cursor: pointer;
  position: absolute;
  right: 0;
}
.btn-voltar {
  cursor: pointer;
  border-radius: 100%;
  padding: 5px;
  position: absolute;
}
.btn-voltar i {
  color: unset;
}
.logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imagem-modulo {
  cursor: pointer;
}
.header-divisor {
  background-image: linear-gradient(270deg, #b2862c, #e0c25d);
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #000000;
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 12px;
  padding: 6px 0px;
}
</style>

<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.columns-resize-bar:hover {
  border-left: 1px dashed #ccc;
}
</style>
import Vue from "vue";
import FetchService from "./fetch-service.js";

const vue = new Vue();

class AprovacaoClienteService extends FetchService {

    listarFiltros() {
        return {
            todos: null,
            quemIndicou: null,
            nome: null,
            email: null,
            telefone: null,
            data: null,
            ordenarPor: 0,
            ordem: 0,
            pagina: 1,
            itensPagina: 10,
        };
    }

    async listarCampos() {
        return [
            {
                descricao: "Quem indicou",
                valor: "quemIndicou",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Nome",
                valor: "nome",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "E-mail",
                valor: "email",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Telefone",
                valor: "telefone",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Data",
                valor: "dataFormatada",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
        ]
    }

    async listarAprovacoes(filtro) {
        const route = `${vue.$globals.endpoint}cliente/listaClientesAprovacao`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            false, 
            route
        );
    }

    async exportarAprovacoesExcel(filtro) {
        const route = `${vue.$globals.endpoint}cliente/exportarClientesAprovacaoExcel`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            true, 
            route
        );
    }

    async cadastrar(obj) {
        const route = `${vue.$globals.endpoint}aprovacaoCliente`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async atualizar(obj) {
        const route = `${vue.$globals.endpoint}cliente/atualizarAprovacao`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async atualizaStatus(clienteId, statusId, obj) {
        const route = `${vue.$globals.endpoint}cliente/atualizaStatus?clienteId=${clienteId}&clienteStatusId=${statusId}`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    listarFiltrosPorCliente(id) {
        return {
            todos: null,
            nome: null,
            email: null,
            telefone: null,
            dataCadastro: null,
            clienteStatus: null,
            gerouVoucher: null,
            clienteIndicacaoId: id,
            ordenarPor: 0,
            ordem: 0,
            pagina: 1,
            itensPagina: 10,
        };
    }

    async listarCamposPorCliente() {
        return [
            {
                descricao: "Nome",
                valor: "nome",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "E-mail",
                valor: "email",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Telefone",
                valor: "telefone",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Data",
                valor: "dataFormatada",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Status",
                valor: "clienteStatus",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Gerou Voucher",
                valor: "gerouVoucher",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
        ]
    }

    async listarAprovacoesPorCliente(filtro) {
        const route = `${vue.$globals.endpoint}cliente/listaClientesIndicacao`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            false, 
            route
        );
    }

    async exportarAprovacoesExcelPorCliente(filtro) {
        const route = `${vue.$globals.endpoint}cliente/exportarClientesIndicacaoExcel`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            true, 
            route
        );
    }

    async totalAprovacoesPendentes() {
        const route = `${vue.$globals.endpoint}cliente/totalAprovacoesPendentes`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }
}

export default AprovacaoClienteService;